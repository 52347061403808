export function init() {

	var $mainSlider = document.querySelectorAll('.main-slider'),
		$interiorSlider = document.querySelectorAll('.interior-slider'),
		$productSlider = document.querySelectorAll('.product-slider'),
		$mainInteriorSlider = document.querySelectorAll('.main-interior-slider'),
		$historySlider = document.querySelectorAll('.history-slider');

	if($mainSlider) {
		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 3000,
			slidesToShow: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($mainInteriorSlider) {
		$('.main-interior-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($interiorSlider) {
		$('.interior-slider').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 3000,
			slidesToShow: 2,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
		      	}
		   	}]
		});	
	}

	if($productSlider) {
		$('.product-slider').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});		
	}

	if($historySlider) {
		$('.history-slider').slick({
			infinite: true,
			speed: 1000,
			autoplaySpeed: 3000,
			slidesToShow: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});	

		$('.history-title-el').on('click', function(){
			var $self = $(this),
				index = $self.index();

			$('.history-slider').slick('slickGoTo', index);
		});

		$('.history-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			$('.history-title-el').removeClass('active');

			$('.history-title-el').eq(nextSlide).addClass('active');
		});
	}
}