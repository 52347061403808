import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as scrollEvent from '../eventHandlers/scrollEvent';

import * as powerTip from 'powertip';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	document.addEventListener('DOMContentLoaded', function(){
		var promise = document.getElementById('vid').play();

		if (promise !== undefined) {
		  promise.then(_ => {
		    // Autoplay started!
		  }).catch(error => {
		    // Autoplay was prevented.
		    // Show a "Play" button so that user can start playback.
		  });
		}
	});

	var $lightgallery = $('.lightgallery'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		$interiorSlider = document.querySelectorAll('.interior-slider'),
		$productSlider = document.querySelector('.product-slider'),
		$historySlider = document.querySelectorAll('.history-slider'),
		$mainInteriorSlider = document.querySelectorAll('.main-interior-slider'),
		$video = document.querySelector('.video'),
		$powertip = $('.powertip');

	if($mainSlider || $mainInteriorSlider || $historySlider || $productSlider || $interiorSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".lightgallery").lightGallery({
			selector: 'this' 
		});
	}

	$('.arrow-down').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$('html,body').animate({
			scrollTop: $('.section-home').position().top
		}, 1000);
	});

	$('.back-to-top').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$('html,body').animate({
			scrollTop: 0
		}, 1000);
	});

	$('.fun a, .coloration-item a, .modal-cont a').tooltip({
		content: function() {
			var element = $( this );
			if(element.attr('data-src')!=undefined) return "<img class='tooltip-coloration' src='"+element.attr('data-src')+"'/><div class='title'>"+element.attr('data-name')+"</div>";
			else return "<div class='title-func'>"+element.attr('data-name')+"</div>";
		},
		position: {
			my: 'center top', 
			at: 'center bottom+10',
			collision: 'none',
			using: function( position, feedback ) {
				$( this ).css( position );
				$( "<div>" )
				.addClass( "arrow" )
				.addClass( feedback.vertical )
				.addClass( feedback.horizontal )
				.appendTo( this );
			}
		}
	});

	$("#modal-upholstery").dialog({
		autoOpen: false,
		width: 600,
		show: {
			effect: "blind",
			duration: 300
		},
		hide: {
			effect: "blind",
			duration: 300
		}
	});

	$('.hanging.search').on('click', function(e){
		e.preventDefault();

		$('.search-hld').addClass('active');
	});

	$('#overlay-menu .menu-item-has-children > a').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$(this).toggleClass('active').next('.sub-menu').slideToggle();
	});

	$('.close-search').on('click', function(){
		$('.search-hld').removeClass('active');
	});

	$("#opener").click(function() {
		$("#modal-upholstery").addClass('active').dialog("open");
	});

	$('.product-item-content a, .modal-cont a').on('click',function(e){ return false; })

	if($powertip) {
		$('.powertip').powerTip({
			placement: 's',
			smartPlacement: true,
			offset: 10,
			fadeInTime: 100,
			fadeOutTime: 75
		});
	}

	if($productSlider) {
		var paneContainer = document.querySelector('.product-item-content');

		$('.product-img').each(function(i, el){
			new Drift(el, {
			  paneContainer: paneContainer,
	          zoomFactor: 2,
	          hoverBoundingBox: true
			});
		});
	}

	if($video) {
		$video.addEventListener('click', function(){
			var vid = $('.video');

			if(vid.hasClass('playing')) {
				vid.removeClass('playing');
				$video.pause();	
			} else {
				vid.addClass('playing');
				$video.play();
			}
		}, false);
	}

	$('.features a').on('click', function(){
		var $self = $(this);
		if($self.hasClass('list')) {
			$('.interiors--columns').fadeOut(function(){
				$('.interiors--list').fadeIn();
			});
		} else if($self.hasClass('column')) {
			$('.interiors--list').fadeOut(function(){
				$('.interiors--columns').fadeIn();
			});
		}
	});

	if($('.porownaj').length) {
        $('.porownaj').find('input[type=checkbox]').prop('checked', true);
    }

    if($('#vid').length) {
		var vid = document.getElementById("vid");
    }

    $('.fvat-label input').on('click', function() {
		$('.hidden-input').toggleClass('show');
	});

	$('#wykonczenie').on('click', 'li', function(){
		var thisText = $(this).find('.wykonczenie-span').html();

		$('#wykonczenie-txt').html(thisText);
	});

	$('#wybarwienie').on('click', 'li', function(){
		var thisText = $(this).find('.wybarwienie-span').html();

		$('#wybarwienie-txt').html(thisText);
	});

    // dla strony bampa której aktualnie nie ma
 //    $('.img-max-hld').on('click', function(){
	// 	$('.popup-hld--movie').addClass('js-show');

	// 	vid.volume = 0.1;
	// 	vid.play();
	// });

	$('.btn--buynow').on('click', function(){
		$('.popup-hld--bamp').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');

		// vid.pause();
	});
});